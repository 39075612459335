(function() {
    window.apos = window.apos || {};
    var data = document.body && document.body.getAttribute('data-apos');
    Object.assign(window.apos, JSON.parse(data || '{}'));
    if (data) {
      document.body.removeAttribute('data-apos');
    }
    if (window.apos.modules) {
      for (const module of Object.values(window.apos.modules)) {
        if (module.alias) {
          window.apos[module.alias] = module;
        }
      }
    }
})();import index_0App from "/home/nodeapps/Oc72/node_modules/apostrophe/modules/@apostrophecms/util/ui/src/index.js";


import index_1App from "/home/nodeapps/Oc72/node_modules/apostrophe/modules/@apostrophecms/admin-bar/ui/src/index.js";


import index_2App from "/home/nodeapps/Oc72/node_modules/apostrophe/modules/@apostrophecms/video-widget/ui/src/index.js";


import index_3App from "/home/nodeapps/Oc72/modules/beautiful-phones/ui/src/index.js";


import index_4App from "/home/nodeapps/Oc72/modules/service-price/ui/src/index.js";


import index_5App from "/home/nodeapps/Oc72/modules/rating/ui/src/index.js";


import index_6App from "/home/nodeapps/Oc72/modules/asset/ui/src/index.js";


import index_7App from "/home/nodeapps/Oc72/modules/form-sender/ui/src/index.js";


import index_8App from "/home/nodeapps/Oc72/modules/is-mobile/ui/src/index.js";



import index_0Stylesheet from "/home/nodeapps/Oc72/node_modules/apostrophe/modules/@apostrophecms/image-widget/ui/src/index.scss";


import index_1Stylesheet from "/home/nodeapps/Oc72/modules/asset/ui/src/index.scss";index_0App();
index_1App();
index_2App();
index_3App();
index_4App();
index_5App();
index_6App();
index_7App();
index_8App();