export default () => {
  const ratingUpdater = () => {
    const ratingBlocks = document.querySelectorAll('.app-review__active');
    ratingBlocks.forEach(ratingBlock => {
      console.log(ratingBlock, ratingBlock.querySelector('.app-review__value_count'));
      ratingBlock.querySelector('.app-review__rating_active').style.width = ratingBlock.querySelector('.app-review__value_count').textContent * 100 / 5 + '%';
    });
  };
  apos.util.onReady(ratingUpdater);
};
