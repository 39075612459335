export default () => {
  const beautifyPhones = () => {
    document.querySelectorAll('[href*=tel]').forEach(phoneLink => {
      // Check if the input is of correct
      const cleaned = ('' + phoneLink.textContent).replace(/\D/g, '');

      const match = cleaned.match(/^(7|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);

      if (match) {
        const intlCode = (match[1] ? '8 ' : '');
        phoneLink.textContent = [intlCode, '(', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('');
      }
    });
  };

  apos.util.onReady(beautifyPhones);
};
