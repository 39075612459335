const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const validatePhone = (input) => {
  return !(input.value.replace(/\D/g, "") === "" || input.value.replace(/\D/g, "").length < 10);
};

const formAddError = (input) => {
  input.parentElement.classList.add("_error");
  input.classList.add("_error");
};

const formRemoveError = (input) => {
  input.parentElement.classList.remove("_error");
  input.classList.remove("_error");
};

const formValidate = (form) => {
  let error = 0;
  const formRequires = form.querySelectorAll("[data-form_require]");
  for (let index = 0; index < formRequires.length; index++) {
    const input = formRequires[index];
    formRemoveError(input);
    if (input.dataset.form_input_email != null) {
      if (!/\S+@\S+\.\S+/.test(input.value)) {
        formAddError(input);
        ++error;
      }
    } else if (input.dataset.form_input_tel != null) {
      if (!validatePhone(input)) {
        formAddError(input);
        ++error;
      }
    } else if (input.getAttribute("type") === "checkbox" && !input.checked) {
      formAddError(input);
      ++error;
    } else {
      if (input.value === "") {
        formAddError(input);
        ++error;
      }
    }
  }

  return error;
};

export default () => {
  const formsRegister = () => {
    const forms = document.querySelectorAll("[data-form]");
    if (forms.length > 0) {
      forms.forEach((form) => {
        async function formSend(e) {
          e.preventDefault();
          let error = formValidate(form);
          if (form.hasAttribute("data-survey")) {
            error = 0;
          }
          if (error === 0) {
            form.classList.add("_sending");
            let result = await apos.http.post('/api/v1/@apostrophecms/email/send', {
              body: {
                name: e.target.name.value,
                phone: e.target.tel.value,
                email: e.target.email.value,
                slug: document.location.pathname
              }
            });
            form.querySelector('.consultation__title').innerHTML = result.message;
            form.querySelector('.consultation__title').classList.add('_sent');
            sleep(2500).then(r => { form.querySelector('.consultation__title').innerHTML = 'Запишитесь на бесплатную консультацию'; form.querySelector('.consultation__title').classList.remove('_sent') })
            form.reset();
          } else {
            let result = {
              message: "Проверьте правильность ввода полей!",
            };
            form.querySelector('.consultation__title').innerHTML = result.message;
            form.querySelector('.consultation__title').classList.add('_error');
            sleep(2500).then(r => { form.querySelector('.consultation__title').innerHTML = 'Запишитесь на бесплатную консультацию'; form.querySelector('.consultation__title').classList.remove('_error') })
          }
        }

        form.addEventListener("submit", formSend);
      });
    }
  }

  apos.util.onReady(formsRegister);
}